<template>
  <Card class="p-mt-2">

    <template #header>
      <div class="text-right mr-5 mt-2">
        <div class="mt-2 pt-4">
          <Button  icon="pi pi-save" class="p-button-rounded p-button-success p-button-text mr-4 mt-3" :label="t('action.salva-bozza')" @click="saveDraft" />
          <Button :label="t('action.prossimo')" class="mt-4" icon="pi pi-angle-right" iconPos="left" @click="nextPage" />
        </div>
      </div>
    </template>

    <template #content>
      <ContrattoData :contratto="contratto"/>
    </template>

    <template #footer>
      <div class="text-right">
        <Button  icon="pi pi-save" class="p-button-rounded p-button-success p-button-text p-mr-4 p-mt-2" :label="t('action.salva-bozza')" @click="saveDraft" />
        <Button :label="t('action.prossimo')" class="p-mt-4" icon="pi pi-angle-right" iconPos="left" @click="nextPage"/>
      </div>
    </template>

  </Card>
</template>

<script setup>
import ContrattoData from "@/components/contratto/ContrattoData";
import eventService from "@/services/EventService";
import {onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import contrattoRestService from "@/services/ContrattoRestService";
import useMessage from "@/composable/useMessage";
import router from "@/router";

const emit = defineEmits(['next-page']);

const store = useStore();
const {success} = useMessage();
const {t} = useI18n();
const contratto = ref({});


onMounted(() => {
  contratto.value = Object.assign({}, store.getters['contrattoWizard/contratto']);
  eventService.on("contratto-valid", onContrattoValid);
  eventService.on("on-contratto-data", onContrattoData);
});

onUnmounted(() => {
  eventService.off("contratto-valid", onContrattoValid);
  eventService.off("on-contratto-data", onContrattoData);
});

async function onContrattoValid(data) {
  contratto.value = await contrattoRestService.saveDraft(data);
  await store.dispatch("contrattoWizard/setContratto", contratto.value);
  emit("next-page", {pageIndex: 0});
}

async function onContrattoData(data) {
  contratto.value = await contrattoRestService.saveDraft(data);
  await store.dispatch("contrattoWizard/setContratto", contratto.value);
  success();
}

const saveDraft = async () => {
  eventService.emit("contratto-data");
  await router.push("/contratti");
}

const nextPage = () => {
  eventService.emit("contratto-validate");
};

</script>
